<template>
  <div>
    <!-- List physical objects -->
    <BaseList
      :list-query="objects"
      :name-map="nameMap"
      route="object"
      :sort="
        (o1, o2) => {
          let a1 = o1.artefact || this.$t('pages.artefacts.unknown');
          let a2 = o2.artefact || this.$t('pages.artefacts.unknown');
          return a1.localeCompare(a2, 'sv');
        }
      "
      class="physical-object-list"
      locale-section="pages.physicalObjects"
    >
      <template v-slot:extra="{ element }">
        {{ $t("pages.physicalObjects.of") }}
        {{
          element.material
            ? element.material.toLowerCase()
            : $t("pages.materials.unknown")
        }}
      </template>
    </BaseList>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "PhysicalObjectListUnknown",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      objects: {
        query: gql`
          query listPhysicalObjectsWithNoName {
            list: listPhysicalObjectsWithNoName {
              material
              artefact
              id
            }
          }
        `,
      }
    };
  },
  methods: {
    missingName(id) {
      return this.$t("pages.physicalObjects.unknown", [id]);
    },
    nameMap(element) {
      return {
        name: element.artefact || this.missingName(element.id),
        ...element
      };
    }
  },
};
</script>
